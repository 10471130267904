/* eslint no-undef: "off" */
/* eslint no-unused-vars: "off" */
/* eslint object-shorthand: [2, "consistent"] */

const initOutdoorActiveDetail = (id) => {
  // the configuration of the FlexView API detail page only needs the id
  const conf = {
    id: id,
    actionOpenType: 'in-page',
  }
  // instantiate FlexView API detail page
  oa.api.detailpage(conf)
}

export default ({ app }, inject) => {
  inject('initOutdoorActiveDetail', initOutdoorActiveDetail)
}
