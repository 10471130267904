import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'

const errorimage = require('@/assets/loading.gif')
const loadimage = require('@/assets/loading.gif')

Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
})
