/* eslint no-undef: "off" */
/* eslint no-unused-vars: "off" */
/* eslint object-shorthand: [2, "consistent"] */

const initLocalTourenDetails = (id) => {
  console.log('id', id)
  if (process.env.NODE_ENV !== 'development') {
    oa.api.trackDetail(id)
  }
}

export default ({ app }, inject) => {
  inject('initLocalTourenDetails', initLocalTourenDetails)
}
