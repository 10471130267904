/* eslint no-undef: "off" */
/* eslint no-unused-vars: "off" */
/* eslint object-shorthand: [2, "consistent"] */

const initLocalTouren = (id) => {
  let ids = null
  if (id.length > 1) {
    ids = id
  } else {
    ids = id[0]
  }
  if (process.env.NODE_ENV !== 'development') {
    oa.api.trackTeaser(ids)
  }
}

export default ({ app }, inject) => {
  inject('initLocalTouren', initLocalTouren)
}
