//
//
//
//
//
//

import error404 from '@/components/error/404.vue'
export default {
  name: 'ErrorPage',
  components: {
    error404: () => import('@/components/error/404.vue'),
  },
  layout: 'clean',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      let error = null
      if (this.error.statusCode === 404) {
        error = error404
      }
      // catch everything else
      return error
    },
  },
}
