import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f12c3d84 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _5c681c7e = () => interopDefault(import('../pages/social-login.vue' /* webpackChunkName: "pages/social-login" */))
const _2e8e529c = () => interopDefault(import('../pages/touren-tipps/lokale-touren/detail.vue' /* webpackChunkName: "pages/touren-tipps/lokale-touren/detail" */))
const _731cbb8c = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/datenschutz",
    component: _f12c3d84,
    name: "datenschutz___de"
  }, {
    path: "/social-login",
    component: _5c681c7e,
    name: "social-login___de"
  }, {
    path: "/en/datenschutz",
    component: _f12c3d84,
    name: "datenschutz___en"
  }, {
    path: "/en/social-login",
    component: _5c681c7e,
    name: "social-login___en"
  }, {
    path: "/touren-tipps/lokale-touren/detail",
    component: _2e8e529c,
    name: "touren-tipps-lokale-touren-detail___de"
  }, {
    path: "/en/touren-tipps/lokale-touren/detail",
    component: _2e8e529c,
    name: "touren-tipps-lokale-touren-detail___en"
  }, {
    path: "/en/*",
    component: _731cbb8c,
    name: "all___en"
  }, {
    path: "/*",
    component: _731cbb8c,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
