const initThomasWidget = ({ id }) => {
    window.TWEBSHOP = {
        config: 'Rheinwelten_tds',
        id,
        maxServiceCount: 10,
        theme: {
            sticky: {
                offset: 80,
                zIndex: 1
            }
        }
    }

    // Create element and set script loader.
    document.getElementById('thomasWrapper').innerHTML
       = `
            ${styles}
            <tws-experience-widget data-id="${id}"></tws-experience-widget>
            <script src="https://api.vardump.de/app/experience/widget/app.staging.js"></script>
        `

    // Call node scripts.
    nodeScriptReplace(document.getElementById('thomasWrapper'))
}

function nodeScriptReplace(node) {
    if ( nodeScriptIs(node) === true ) {
            node.parentNode.replaceChild( nodeScriptClone(node) , node );
    }
    else {
            let i = -1; const children = node.childNodes;
            while ( ++i < children.length ) {
                  nodeScriptReplace( children[i] );
            }
    }

    return node;
}
function nodeScriptClone(node){
    const script  = document.createElement("script");
    script.text = node.innerHTML;

    let i = -1; const attrs = node.attributes; let attr;
    while ( ++i < attrs.length ) {
          script.setAttribute( (attr = attrs[i]).name, attr.value );
    }
    return script;
}

function nodeScriptIs(node) {
    return node.tagName === 'SCRIPT';
}

const styles = `
<style>
    #twebshop {
        --tw-button-color: #003b5c;
        --tw-button-text-color: #fff;
        --tw-button-price-color: #2e921d;
        --tw-button-price-text-color: #fff;
        --tw-box-color: #1d6bb1;
        --tw-box-text-color: #fff;
        --tw-booking-color: #eae9e8;
        --tw-booking-text-color: #3d3d3d;
    }

    #twebshop .inline-flex {
        width: 100% !important;
    }

    #twebshop .text-base {
        font-family: 'Swear Deck' !important;
    }

    #twebshop .inline-flex div[name="custom-input-number"] {
        width: calc(100% - 180px) !important;
    }

    #twebshop .inline-flex button.bg-tw-button {
        width: 90px !important;
    }

    #twebshop #experienceGroupResult .inline-flex {
        border-radius: 20px !important;
        text-transform: uppercase !important;
        background: #ED6403 !important;
        font-family: 'GT America' !important;
        margin-top: 10px !important;
    }

    #twebshop a.bg-white strong {
        font-family: 'Apercu Pro' !important;
        font-weight: 400 !important;
    }

    #twebshop #experienceGroupResult .inline-flex:hover {
        background: #f18335 !important;
    }

    #twebshop #experienceGroupResult div:first-child div:last-child .flex.flex-wrap div:last-child {
        width: 100% !important;
        margin-top: 20px !important;
    }

    #twebshop #experienceGroupResult div:first-child div:last-child .flex.flex-wrap div:last-child div:last-child {
        margin-top: 0 !important;
    }

    #twebshop #experienceGroupResult div:first-child div:last-child .flex.flex-wrap div:last-child div:last-child div {
        width: calc(100% / 3) !important;
        font-size: 12px !important;
    }

    #twebshop #experienceGroupResult div:first-child div:last-child div:first-child label {
        opacity: 0.6 !important;
    }

    #twebshop a[href="#/cart"],
    #twebshop .leading-none span:first-child {
        display: none !important;
    }

    #twebshop .t-shoppingcart-modal .t-modal__close {
        right: unset !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        max-width: 90%;
    }

    #twebshop .t-shoppingcart-modal .t-remove {
        width: 25px !important;
        height: 25px !important;
    }
</style>
`

export default ({ app }, inject) => {
    inject('initThomasWidget', initThomasWidget)
}