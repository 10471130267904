import { AUTH_URL } from '../helpers/url'

export const state = () => ({
  links: null,
  tippsDetailUrl: '',
  isLoading: false,
  isMenuOpened: false,
  isLoginOpened: false,
  isProfileOpened: false,
  showNotification: false,
  partners: [],
  tippsFilters: null,
  company: null,

  favourites: [],
})

export const mutations = {
  SET_MENU(state, payload) {
    state.isMenuOpened = payload
  },
  SET_NOTIFICATION(state, payload) {
    state.showNotification = payload
  },
  SET_LOGIN(state, payload) {
    state.isLoginOpened = payload
  },
  SET_PROFILE(state, payload) {
    state.isProfileOpened = payload
  },
  SET_GLOBAL_LINKS(state, payload) {
    state.links = payload
  },
  SET_TRIP_DETAIL_URL(state, payload) {
    state.tippsDetailUrl = payload
  },
  SET_GLOBAL_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_PARTNERS(state, payload) {
    state.partners = payload
  },
  SET_TIPPS_FILTERS(state, payload) {
    state.tippsFilters = payload
  },
  SET_COMPANY_INFO(state, value) {
    state.company = value
  },
  SET_FAVOURITES(state, value) {
    state.favourites = value
  },
  UPDATE_FAVOURITE(state, { identifier, flag }) {
    state.favourites[
      state.favourites.findIndex((x) => x.identifier === identifier)
    ].favourite = flag
  },
}

export const getters = {
  getFavourites: (state) => state.favourites,
  getFavouritebyIdentifier: (state) => (identifier) =>
    state.favourites.find((x) => x.identifier === identifier),
}

export const actions = {
  getContentDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(payload.url, payload)
        .then((res) => {
          commit('SET_COMPANY_INFO', res.page.company[0])
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setTimeout(() => {
            commit('SET_GLOBAL_LOADING', false)
          }, 1000)
        })
    })
  },
  getContent({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get('/erlebniswelten/bluetenpracht-apfelsaft')
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setTimeout(() => {
            commit('SET_GLOBAL_LOADING', false)
          }, 1000)
        })
    })
  },

  async setFavourite({ commit, getters }, payload) {
    if (getters.getFavouritebyIdentifier(payload.identifier)) {
      commit('UPDATE_FAVOURITE', payload)
    }

    if (payload.flag) {
      await this.$axios.post(
        AUTH_URL + '/api/favourites/' + payload.identifier,
        {},
        { headers: { Authorization: this.$auth.strategy.token.get() } }
      )
      if (payload.showNotification) {
        commit('SET_NOTIFICATION', true)
        setTimeout(() => {
          commit('SET_NOTIFICATION', false)
        }, 3000)
      }
    } else {
      await this.$axios.delete(
        AUTH_URL + '/api/favourites/' + payload.identifier,
        { headers: { Authorization: this.$auth.strategy.token.get() } }
      )
    }
  },

  async loadFavourites({ commit }) {
    try {
      const { data } = await this.$axios.get(AUTH_URL + '/api/favourites', {
        headers: { Authorization: this.$auth.strategy.token.get() },
      })
      commit('SET_FAVOURITES', data.data)
    } catch (e) {
      /* unauth */
    }
  },

  getLinks({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/?type=834')
        .then(({ data }) => {
          commit('SET_GLOBAL_LINKS', data)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setTimeout(() => {
            commit('SET_GLOBAL_LOADING', false)
          }, 1000)
        })
    })
  },
}
