export const Collapse = () => import('../../components/sections/global/collapse.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c))
export const ContentLoader = () => import('../../components/sections/global/contentLoader.vue' /* webpackChunkName: "components/content-loader" */).then(c => wrapFunctional(c.default || c))
export const ContentProvider = () => import('../../components/sections/global/contentProvider.vue' /* webpackChunkName: "components/content-provider" */).then(c => wrapFunctional(c.default || c))
export const HeaderLayout = () => import('../../components/sections/global/headerLayout.vue' /* webpackChunkName: "components/header-layout" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/sections/global/notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const DownloadIcon = () => import('../../components/sections/icons/downloadIcon.vue' /* webpackChunkName: "components/download-icon" */).then(c => wrapFunctional(c.default || c))
export const EmailIcon = () => import('../../components/sections/icons/emailIcon.vue' /* webpackChunkName: "components/email-icon" */).then(c => wrapFunctional(c.default || c))
export const FilterIcon = () => import('../../components/sections/icons/filterIcon.vue' /* webpackChunkName: "components/filter-icon" */).then(c => wrapFunctional(c.default || c))
export const LightbulbIcon = () => import('../../components/sections/icons/lightbulbIcon.vue' /* webpackChunkName: "components/lightbulb-icon" */).then(c => wrapFunctional(c.default || c))
export const LightbulbsmallIcon = () => import('../../components/sections/icons/lightbulbsmallIcon.vue' /* webpackChunkName: "components/lightbulbsmall-icon" */).then(c => wrapFunctional(c.default || c))
export const LikeIcon = () => import('../../components/sections/icons/likeIcon.vue' /* webpackChunkName: "components/like-icon" */).then(c => wrapFunctional(c.default || c))
export const LocationIcon = () => import('../../components/sections/icons/locationIcon.vue' /* webpackChunkName: "components/location-icon" */).then(c => wrapFunctional(c.default || c))
export const LocationIconWhite = () => import('../../components/sections/icons/locationIconWhite.vue' /* webpackChunkName: "components/location-icon-white" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
