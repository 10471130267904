import Vue from 'vue'

Vue.directive('follower-cursor', {
  bind: (el, binding, vnode) => {
    // Create cursor component.
    const cursor = document.getElementById('follower-cursor')

    el.addEventListener('mouseenter', function () {
      cursor.classList.add('active')
      el.addEventListener('mousemove', followerCursorListener)
    })

    el.addEventListener('mouseleave', function () {
      cursor.classList.remove('active')
      el.removeEventListener('mousemove', followerCursorListener)
    })
  },
})

/**
 * Special cursor that appears on element when it's hovered.
 *
 * @param {Event} e
 */
function followerCursorListener(e) {
  const cursor = document.getElementById('follower-cursor')

  const off = window.pageYOffset || document.documentElement.scrollTop

  const n = {
    x: e.pageX - 30,
    y: e.pageY - off - 30,
  }

  cursor.style.setProperty('--x', `${n.x}px`)
  cursor.style.setProperty('--y', `${n.y}px`)
}
